<template>
    <div class="al-content">
        <div class="content-top">
            <div class="content-top clearfix">
                <h1 class="al-title " v-html="title"></h1>
            </div>
        </div>

        <div class="row ">

            <div class="col-md-12">
                <div class="panel">
                    <div class="panel-body">
                        <div>
                            <button type="button" class="btn btn-default btn-with-icon" 
                                :class="{'disabled':ViewMode=='Product'}"
                                @click="$router.push('/ImportOrder/Edit/0')"
                                    v-show="checkAcl('Edit')"><i class="ion-plus"></i>Create</button>

                        </div>
                        <div class="mt-3">
                            <table class="table table-hover table-bordered" id="datatable" v-show="ViewMode=='File'">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Quantity</th>
                                        <th>Order Date</th>
                                        <th>Receive Date</th>
                                        <th>Created By</th>
                                        <th style="width:150px" v-show="checkAcl('Edit')||checkAcl('Remove')"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in data" :key="item.Id" >
                                        <td>{{item.Name}}</td>
                                        <td>{{formatNumber(item.Quantity)}}</td>
                                        <td>{{formatDate2(item.OrderDate,true)}}</td>
                                        <td>{{formatDate2(item.ReceiveDate,true)}}</td>
                                        <td>{{item.CreatedBy}}</td>
                                        <td v-show="checkAcl('Remove')">
                                            <span class="editable-buttons">   
                                                <button type="button" class="btn btn-default btn-with-icon btn-sm" @click="$router.push('/ImportOrder/Edit/'+item.Id)" title="Edit" v-show="checkAcl('Edit')">
                                                    <i class="ion-edit p-1"></i>
                                                </button>
                                                <button type="button" class="btn btn-danger btn-with-icon btn-sm ml-3" @click="remove(item.Id,item.Name)" title="Remove" v-show="checkAcl('Remove')">
                                                    <i class="ion-trash-b p-1"></i>
                                                </button>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>


                        </div>
                    </div>
                </div>
            </div>
        </div>

        <MDBModal v-model="showUpload" size="sm">
            <MDBModalHeader>
                <MDBModalTitle> Upload File Revenue </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
                Select a range of date when products were sold:
                <Datepicker v-model="dateUpload" range dark class="mb-3" autoApply :format="'dd/MM/yyyy'">
                    <template #calendar-header="{ index, day }">
                        <div :class="index === 5 || index === 6 ? 'calenda-red-color' : ''">
                            {{ day }}
                        </div>
                    </template>
                </Datepicker>
                <button type="button" class="btn btn-primary btn-with-icon" @click="SelectFileUpload(event)"><i class="ion-document"></i>Select a file</button>
                <div class="mt-3" id="uploadFileInfo">

                </div>
            </MDBModalBody>
            <MDBModalFooter>
                <button type="button" class="btn btn-primary" @click="UploadInventory"><i class="ion-android-upload"></i> Upload</button>
                <button type="button" class="btn btn-danger" @click="showUpload=false"><i class="ion-close"></i> Cancel</button>
            </MDBModalFooter>
        </MDBModal>

       
    </div>
</template>
<style>

    .dataTables_wrapper .dataTables_paginate .paginate_button {
        color: white !important;
    }
</style>
<script>
    //https://therichpost.com/vue-3-datatable-with-export-buttons-print-csv-copy-with-dynamic-data/
    //Datatable Modules
    import "datatables.net-dt/js/dataTables.dataTables"
    import "datatables.net-dt/css/jquery.dataTables.min.css"
    //import "datatables.net-buttons/js/dataTables.buttons.js"
    //import "datatables.net-buttons/js/buttons.colVis.js"
    //import "datatables.net-buttons/js/buttons.flash.js"
    //import "datatables.net-buttons/js/buttons.html5.js"
    //import "datatables.net-buttons/js/buttons.print.js"
    import $ from 'jquery';
    import _ from 'lodash'
    import 'datatables.net-responsive'

    //========== Datetime picker
    //https://vue3datepicker.com/installation/#global
    import Datepicker from 'vue3-date-time-picker';
    import 'vue3-date-time-picker/dist/main.css'

    import {
        //MDBBtn,
        MDBModal,
        MDBModalHeader,
        MDBModalTitle,
        MDBModalBody,
        MDBModalFooter,
    } from 'mdb-vue-ui-kit';

    import c from '@/mylib/common'
    import { mapState,mapActions } from 'vuex'
    export default {
        name: `ImportOrder`,
        data: function () {
            return {
                title:'',
                dateUpload:null,
                LayoutModel: {},
                data: [],
                dataProductSold: [],
                dataTable: null,
                dataTableProductSold: null,
                showUpload: false,
                FileUpload: null,
                ViewMode: 'File',
            }
        },
        
        components: {
            Datepicker,
            //MDBBtn,
            MDBModal,
            MDBModalHeader,
            MDBModalTitle,
            MDBModalBody,
            MDBModalFooter,
        },
        computed: mapState({
            isCheckedLogin: state => state.view.isCheckedLogin,
        }),
        methods: {
            ...mapActions({
                'loading': 'view/setLoading',
                'showModal': 'view/showModal',
                'showToast': 'view/showToast',
                'setGroups': 'user/setGroups',                
                'call': 'api/call'
            }),
            checkAcl(action) {
                return this.$store.state.session.acl.includes(this.$options.name + "/" + action);
            },
            checkFullAcl(router) {
                return this.$store.state.session.acl.includes(router);
            },
            start() {
                this.getAll();
                
            },
            formatDate(dstr,notime) {
                let d = new Date(dstr);
                if (d == "Invalid Date") return "";
                return c.formatDate(d, notime);
            },
            formatDate2(dstr, notime) {
                let d = new Date(dstr);
                if (d == "Invalid Date") return "";
                return c.formatDate2(d, notime);
            },
            formatNumber(n) {                
                return c.formatNumber(n);
            },
            async getAll() {
                this.loading(true);
                if (this.dataTableProductSold != null) this.dataTableProductSold.destroy();
                if (this.dataTable != null) this.dataTable.destroy();
                const rs = await this.call({ Action: 'ImportOrder/List' })
                
                this.error = rs.Error;
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                    this.loading(false);
                    //no permission                    
                    if (rs.Error.includes("Permission Denied")) {
                        window.history.back();
                    }

                } else {                    
                    this.data = rs.Data;
                    this.title = `ImportOrder`;
                    setTimeout(this.doTable, 200);                    
                }
            },
            async remove(id, name) {

                this.loading(true);
                const rs = await this.call({ Action: 'ImportOrder/Remove', Object: { Id: id } })
                this.loading(false);
                if (rs.Error != "") {                    
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });

                } else {
                    _.remove(this.data, function (n) {
                        return n.Id == id;
                    });
                    this.showToast({ title: '', content: `Remove order "${name}" successfull.`, type: 'success' });
                }
            },
            doTable() {
                const _this = this;                
                _this.dataTable = $('#datatable').DataTable({
                    responsive: true,
                    "order": [[2, "desc"]]
                    
                });
                this.loading(false);
            },
            
        },
        
        created() {
            
        },
        mounted() {            
            //if already login then start, 
            //else will watch isCheckedLogin to catch its change
            //to call start function
            if (this.isCheckedLogin) this.start();
        },
        watch: {
            //catch status from not login to logged in 
            //to call start function.
            //Apply for reload page issue
            isCheckedLogin(newVal) {
                console.log("user index newVal", newVal);
                if (newVal) {
                    this.start();
                }
            }
        }
    };
</script>
